import { MapTo } from '@adobe/aem-react-editable-components'
import withAsyncImport from 'utils/withAsyncImport'

const BannerFeature = withAsyncImport(() =>
  import('./BannerFeatureMap')
)

const BannerFeatureEditableConfig = {
  emptyLabel: 'Banner - Feature',

  isEmpty: function (props) {
    return !props || !props.configured
  }
}

export default MapTo('aramark/components/content/banner-feature')(BannerFeature, BannerFeatureEditableConfig)
